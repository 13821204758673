export interface AgeRange {
  maxAge: number;
  minAge: number;
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  PreferNotToSay = 'preferNo',
}
